<template>
  <v-container>
    <v-tabs v-model="tab" fixed-tabs centered dark background-color="primary">
      <v-tab>NID Images</v-tab>
      <v-tab>NID Text</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="images">
        <v-card flat>
          <v-card-title class="text-h5 font-weight-regular mb-5">
            Test NID OCR with Images
          </v-card-title>
          <v-row justify="center">
            <v-col md="8" sm="10" class="pt-10 text-center">
              <v-file-input
                solo
                show-size
                label="Front Image"
                placeholder="Front side of NID"
                v-model="frontImage"
              />
              <v-file-input
                solo
                show-size
                label="Back Image"
                placeholder="Back side of NID"
                v-model="backImage"
              />
              <v-btn
                color="success"
                :disabled="!(frontImage && backImage)"
                @click="parseNid"
                :loading="loading"
                class="mb-3"
              >
                <v-icon>mdi-cloud-upload</v-icon>&nbsp; Parse NID</v-btn
              >
            </v-col>
            <v-col v-if="parsedNidData" md="11" lg="8" class="pt-4 text-center">
              <v-card class="my-5">
                <v-simple-table :loading="loading" class="text-right">
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="item in items" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td class="text-left text--monospaced pa-3">
                          {{ emptyChecker(item.value(parsedNidData)) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right" width="200px">Front Text</td>
                        <td class="text-left text--monospaced pa-3">
                          <pre class="pre-style">{{ parsedNidData.frontText }}</pre>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right" width="200px">Back Text</td>
                        <td class="text-left text--monospaced pa-3">
                          <pre class="pre-style">{{ parsedNidData.backText }}</pre>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col v-else-if="errorData" md="12" class="pt-4 text-center">
              <v-card dark class="my-5 pa-3">
                <div class="overflow-x-auto">
                  <pre class="red--text text-left">{{ JSON.stringify(errorData, null, 4) }}</pre>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>

      <v-tab-item key="text">
        <v-card flat>
          <v-card-title class="text-h5 font-weight-regular mb-5">
            Test NID OCR with Parsed Texts
          </v-card-title>
          <v-row justify="center">
            <v-col md="10" sm="12" class="pt-2 text-center">
              <v-textarea outlined label="Front Text" rows="3" v-model="frontText" />
              <v-textarea outlined label="Back Text" rows="3" v-model="backText" />
              <v-btn
                color="success"
                :disabled="!(frontText && backText)"
                @click="parseNidText"
                :loading="loading"
                class="mb-3"
              >
                <v-icon>mdi-cloud-upload</v-icon>&nbsp; Parse NID from Text</v-btn
              >
            </v-col>
            <v-col v-if="parsedNidTextData" md="11" lg="8" class="pt-4 text-center">
              <v-card class="my-5">
                <v-simple-table :loading="loading" class="text-right">
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="item in items" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td class="text-left text--monospaced pa-3">
                          {{ emptyChecker(item.value(parsedNidTextData)) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right" width="200px">Front Text</td>
                        <td class="text-left text--monospaced pa-3">
                          <pre class="pre-style">{{ parsedNidTextData.frontText }}</pre>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right" width="200px">Back Text</td>
                        <td class="text-left text--monospaced pa-3">
                          <pre class="pre-style">{{ parsedNidTextData.backText }}</pre>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col v-else-if="errorData" md="12" class="pt-4 text-center">
              <v-card dark class="my-5 pa-3">
                <div class="overflow-x-auto">
                  <pre class="red--text text-left">{{ JSON.stringify(errorData, null, 4) }}</pre>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { OCR_NID, OCR_NID_TEXT } from "@/constants/api";
import moment from "moment";

export default {
  name: "TestNIDOCR",
  metaInfo: { title: "Test - NID Ocr" },
  components: {},
  data: () => ({
    tab: null,
    loading: false,
    parsedNidData: null,
    parsedNidTextData: null,
    errorData: null,
    errorTextData: null,
    frontImage: null,
    backImage: null,
    frontText: null,
    backText: null,
    items: [
      {
        name: "Processed At",
        value: (item) => moment(item.createdAt).format("DD MMM, YYYY \n hh:mm:ss A"),
      },
      { name: "Type", value: (item) => item.type },
      { name: "Nid", value: (item) => item.nid },
      { name: "Date of Birth", value: (item) => item.dob },
      { name: "Name (Bengali)", value: (item) => item.nameBn },
      { name: "Name (English)", value: (item) => item.nameEn },
      { name: "Father Name (Bengali)", value: (item) => item.fatherBn },
      { name: "Father Name (English)", value: (item) => item.fatherEn },
      { name: "Mother Name (Bengali)", value: (item) => item.motherBn },
      { name: "Mother Name (English)", value: (item) => item.motherEn },
      { name: "Spouse Name (Bengali)", value: (item) => item.spouseBn },
      { name: "Spouse Name (English)", value: (item) => item.spouseEn },
      { name: "Blood Group", value: (item) => item.bloodGroup },
    ],
  }),
  mounted() {
    this.backText = this.$store?.state?.test?.testNidOcrData?.backText;
    this.frontText = this.$store?.state?.test?.testNidOcrData?.frontText;
    this.parsedNidData = this.$store?.state?.test?.testNidOcrData?.parsedNidData;
    this.parsedNidTextData = this.$store?.state?.test?.testNidOcrData?.parsedNidTextData;
  },
  methods: {
    showError(err) {
      console.log(err);
      this.$iziToast.error({ title: err || "Something went wrong!" });
    },
    async parseNid() {
      this.loading = true;
      this.errorData = null;
      try {
        const data = new FormData();
        data.append("front", this.frontImage);
        data.append("back", this.backImage);
        const response = await this.$axios.post(OCR_NID, data);
        this.parsedNidData = response.data?.data;
        this.$store.commit("test/setTestNidOcrData", {
          parsedNidData: this.parsedNidData,
        });
      } catch (err) {
        this.errorData = err?.response?.data || err?.message;
      } finally {
        this.loading = false;
      }
    },
    async parseNidText() {
      this.loading = true;
      try {
        const data = new FormData();
        data.append("front", this.frontText);
        data.append("back", this.backText);
        const response = await this.$axios.post(OCR_NID_TEXT, data);
        this.parsedNidTextData = response.data?.data;
        this.$store.commit("test/setTestNidOcrData", {
          frontText: this.frontText,
          backText: this.backText,
          parsedNidTextData: this.parsedNidTextData,
        });
      } catch (err) {
        this.errorData = err?.response?.data || err?.message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.col-sm-10,
.col-sm-12 {
  padding: 30px;
}
</style>
